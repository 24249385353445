<template>
  <pool title="岗位情况" @chooseCount="chooseCount" :dataList="showDataList" :countList="countList" v-loading="loading"></pool>
</template>

<script>
import {
  loadDetail
} from './lib'

import pool from './pool'
export default {
  data () {
    return {
      dataList: [],
      searchParm: {
        jobTitleType: null
      },
      loading: false
    }
  },
  components: {
    pool
  },
  computed: {
    typeList () {
      return this.$store.getters.selectDatas['job_title_type'].map(v => {
        return {
          name: v.label
        }
      })
    },
    showDataList () {
      if (this.searchParm.jobTitleType) {
        return this.dataList.filter(v => {
          if (this.searchParm.jobTitleType === '_null') {
            return v.jobTitleTypeList.length === 0
          } else {
            return v.jobTitleTypeList.includes(this.searchParm.jobTitleType)
          }
        })
      }
      return this.dataList
    },
    countList () {
      let dataMap = {}
      this.dataList.forEach(v => {
        if (v.jobTitleTypeList.length > 0) {
          v.jobTitleTypeList.forEach(item => {
            dataMap[item] = (dataMap[item] || 0) + 1
          })
        } else {
          dataMap['_null'] = (dataMap['_null'] || 0) + 1
        }
      })
      let keys = Object.keys(dataMap)
      let data = this.typeList.filter(v => keys.includes(v.name)).map(v => {
        return {
          key: v.name,
          name: v.name,
          value: dataMap[v.name]
        }
      })
      if (dataMap['_null']) {
        data.push({
          key: '_null',
          name: '无当前岗位',
          value: dataMap['_null']
        })
      }
      data.forEach(v => v.active = this.searchParm.jobTitleType === v.key)
      return data
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    chooseCount (cIndex) {
      let jobTitleType = this.countList[cIndex].key
      if (this.searchParm.jobTitleType === jobTitleType) {
        this.searchParm.jobTitleType = null
      } else {
        this.searchParm.jobTitleType = jobTitleType
      }
    },
    async loadData () {
      this.loading = true
      this.dataList = await loadDetail(this)
      this.loading = false
    }
  }
}
</script>
